export const defaultHashData = [
  { name: "Jim", food: "Pizza" },
  { name: "Adam", food: "Salad" },
  { name: "Sarah", food: "Ice Cream" },
  { name: "Bob", food: "Raisin Bran" },
  { name: "Bill", food: "Bananas" },
  { name: "Aria", food: "Apples" },
  { name: "Bernie", food: "Strawberries" },
  { name: "Donald", food: "Oranges" },
  { name: "Tim", food: "Mangos" },
  { name: "Tammy", food: "Lemons" },
  { name: "Barry", food: "Limes" },
  { name: "Dan", food: "Brussel Sprouts" },
  { name: "Leo", food: "Burgers" },
  { name: "Carter", food: "Chilli" },
  { name: "Alex", food: "Chicken" },
  { name: "Virgil", food: "Fish" },
  { name: "Lorenzo", food: "Ranch Dressing" },
  { name: "Christina", food: "Spicy Chicken" },
  { name: "Rasaan", food: "Alfredo Pasta" },
  { name: "Lisa", food: "Chinese Food" },
  { name: "Chris", food: "Oatmeal" },
  { name: "Daniel", food: "Brocolli" },
  { name: "Fred", food: "Tacos" },
];
